body {
  margin: 0;
  font-family: sans-serif;
  background: #f0f0f0;
  height: 100%;
}

img.App-logo {
  height: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

header.App-header {
  background-color: #222;
  height: 25vh;
  padding: 2vh;
  color: white;
  overflow: hidden;
}

.App-title {
  font-size: 1.5em;
  align-items: center;
}

.App-subtitle {
  text-align: center;
}

.App-intro {
  font-size: large;
}

.logout {
  object-position: right;
}

h1,
.registration-link {
  text-align: center;
}

.loginForm, .registrationForm, .errorForm, .SystemReportForm {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

form.systemReport {
  /*width: 75%;*/
  height: auto;
}

div.registrationForm {
  max-width: fit-content;
}

div.homePage {
  text-align: center;
}

.forgottenPassword {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

label,
input {
  display: block;
  width: 100%;
}

label {
  margin-bottom: 5px;
  height: 22px;
}

input {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #777;
}

.input-feedback {
  color: rgb(235, 54, 54);
  margin-top: -15px;
  font-size: 14px;
  margin-bottom: 20px;
}

span.error {
  font-size: 16px;
  color: red;
  margin-bottom: 15px;
}

.error {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

button, .register-button, .login-button, .forgotten-password-button, .submit-systemreport-button{
  padding: 10px 15px;
  background-color: rgb(70, 153, 179);
  color: white;
  border: 1px solid rgb(70, 153, 179);
  background-color: 250ms;
}